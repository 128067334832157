#more {
    animation: fade-in 600ms 900ms backwards;
}

.more-desc {
    font-size: 25px;
}

.more-button {
    width: auto;
    font-size: 22px;
    display: inline-block;
    background-color: rgb(64 78 237);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    transition: all 200ms ease-in-out;
    animation: fade-in 600ms 1000ms backwards;
}

.more-button:hover {
    scale: 1.02;
    background-color: rgb(72, 86, 235);
}

.more-grid {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 60px;
    animation: fade-in 600ms 400ms backwards;
}

.more-card {
    background-color: rgb(34, 40, 43);
    width: 200px;
    height: 150px;
    padding: 15px;
    margin: auto;
    border-radius: 20px;
    box-shadow: 1px 1px 5px 2px rgb(31, 31, 31);
    display: grid;
    transition: scale 400ms ease-in-out;
}

.more-card:hover {
    scale: 1.01;
}