.premium-card {
    margin: 45px auto;
    max-width: 500px;
    width: 75%;
    /* background-color: rgb(28, 28, 28); */
    background-color: rgb(34, 36, 37);
    border-radius: 12px;
    transition: all 300ms ease-in-out;
}

.premium-card:hover {
    transform: scale(1.02);
    background-color: rgb(35, 39, 43);
}

.premium-card-title {
    /* background-color: rgb(80, 80, 80); */
    background-color: rgb(100, 100, 100);
    border-radius: 12px 12px 0px 0px;
    border-bottom: 1px black solid;
    padding: 15px 0px;
    width: 100%;
    font-size: 20px;
}

.premium-background {
    background-color: rgb(64 78 237);
}

.premium-feature-list {
    padding: 25px 25px;
    font-size: 20px;
}

.item-checkmark {
    display: inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); 
    transform: rotate(45deg);
}

.checkmark_circle {
    position: absolute;
    width:22px;
    height:22px;
    background-color: green;
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:15px;
    background-color: white;
    left:11px;
    top:3px;
}

.checkmark_kick {
    position: absolute;
    width:6px;
    height:3px;
    background-color: white;
    left:6px;
    top:15px;
}

.premium-prices {
    font-size: 18px;
    color: rgb(230, 230, 230);
}

.item-text {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
}

.premium-verus {
    font-size: 32px;
    margin: 0;
    padding: 0;
}

.premium-buy {
    font-size: 30px;
}

.premium-button {
    width: 300px;
    font-size: 22px;
    display: inline-block;
    background-color: rgb(64 78 237);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin-top: 15px;
    transition: all 200ms ease-in-out;
}

.premium-button:hover {
    scale: 1.02;
    background-color: rgb(72, 86, 235);
}

.premium-command {
    background-color: rgb(24, 24, 24);
    color: whitesmoke;
    padding: 0px 5px;
    border-radius: 3px;
}

.premium-buy-instructions, .premium-server-owners {
    color: rgb(202, 202, 202);
}

.premium-line {
    width: 100%;
    margin: 75px 0px;
    border-bottom: 2px rgb(54, 54, 54) solid;
}

.item-crosssign {
    display: inline-block;
    width: 22px;
    height: 22px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); 
    transform: rotate(45deg);
}
  
.crosssign_circle {
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: red;
    border-radius: 11px;
    left: 0;
    top: 0;
}
  
.crosssign_stem,
.crosssign_stem2 {
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.crosssign_stem {
    width: 3px;
    height: 15px;
}
  
.crosssign_stem2 {
    width: 15px;
    height: 3px;
}

.premium-desc {
    transition: all 300ms ease-in-out;
    padding: 0;
    margin: 0;
    font-size: 20px;
}

.premium-desc:hover {
    scale: 1.015;
    color: whitesmoke;
}
