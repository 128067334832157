.card {
    background-color: rgb(31, 35, 38);
    padding: 25px;
    width: 95%;
    margin: auto;
    border-radius: 12px;
    box-shadow: 1px 1px 10px 2px rgb(27, 27, 27);
    display: grid;
    transition: scale 400ms ease-in-out;
    width: 200px;
    height: 200px;
    /* box-shadow: 1px 1px 10px 2px rgb(27, 27, 27);
    background-color: rgb(31, 35, 37);
    width: 200px;
    height: 200px;
    padding: 25px;
    margin: auto;
    border-radius: 12px; */
    place-self: center;
}

.card-title {
    padding: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(56, 56, 56);
    margin: 0;
    font-size: 24px;
}

.card-desc {
    padding: 0;
    padding-top: 20px;
    margin: 0;
    font-size: 20px;
}

.card-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.card:hover {
    scale: 1.01;
}

@media (max-width: 800px) {
    .card {
        width: 175px;
        height: 175px;
    }

    .card-title {
        font-size: 22px;
        padding-bottom: 5px;
    }

    .card-desc {
        padding-top: 10px;
        font-size: 18px;
    }
}