.terms-of-service {
    text-align: left;
    color: rgb(223, 223, 223);
    padding: 30px 60px;
    background-color: rgb(28, 28, 28);
    border-radius: 12px;
    font-size: 18px;
}

.terms-of-service a {
    color: rgb(64, 64, 226);
}

.tos-title {
    font-size: 30px;
    color: white;
}
