.error404-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: relative;
}

.error404-msg {
    width: 100%;
    font-size: 16px;
}

.error404-buttons {
    text-align: center;
    width: 100%;
    margin: auto;
    text-align: center;
}

.error404-button {
    margin: 35px;
    display: inline-block;
    border: none;
    background-color: rgb(64 78 237);
    font-size: 30px;
    padding: 10px 20px;
    border-radius: 20px;
    transition: all 500ms ease;
    color: white;
}

.error404-button:hover {
    scale: 1.02;
    background-color: rgb(72, 86, 235);
}

