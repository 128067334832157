.category {
    margin: 5px;
    width: fit-content;
    padding: 20px;
    border: 1px rgb(24, 24, 24) solid;
    border-radius: 12px;
    background-color: rgb(24, 24, 24);
    color: rgb(196, 196, 196);
    display: inline-block;
    transition: transform 500ms ease;
}

.category:hover {
    color: white;
    background-color: rgb(32, 32, 32);
    border: 1px rgb(32, 32, 32) solid;
}

.activeCategory {
    background-color: rgb(29, 93, 153) !important;
    border: 1px rgb(33, 97, 157) solid;
    color: white;
}

.activeCategory:hover {
    background-color: rgb(35, 99, 159) !important;
}