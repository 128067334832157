nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    position: sticky;
    top: 0px;
    /* margin-right: 25px; */
    z-index: 999;
    transition: background-color 200ms ease-in-out;
}

.navbar-list {
    /* margin-left: min(200px); */
    margin: 0 auto;
    display: flex;
    padding: 0px;
    list-style: none;
}

.navbar-item {
    position: relative;
    padding: 5px 6px;
    margin: 5px;
    transition: transform 200ms ease;
}

.navbar-item a {
    color: gainsboro;
    font-size: 22px;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.navbar-item:hover {
    transform: scale(1.05);
}

.navbar-item:hover a {
    /* color: rgb(218, 218, 218); */
    color: whitesmoke;
    text-decoration: none !important;
}

.navbar-item:hover a:after {
    background-color: rgb(218, 218, 218);
}

.navbar-item a:after {
    content: "";
    position: absolute;
    background: black;
    border-radius: 30px;
    height: 2px;
    width: 0%;
    right: 0px;
    bottom: 2px;
    transition: all 400ms ease;
}

.navbar-item:hover a:after {
    width: 100%;
    left: 0px;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 20px;
    right: 15px;
    width: 28px;
    height: 20px;
    cursor: pointer;
}

.hamburger-bar {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 20px;
    transition: all 100ms ease-in-out;
}

.hamburger-bar:nth-of-type(2) {
    transition: all 50ms ease-in-out;
}

.brand {
    display: flex;
    align-items:center;
    text-decoration: none;
    color: white;
    position: absolute;
    top: 0px;
}

.brand img {
    width: 40px;
    border-radius: 15px;
    margin: 10px;
    margin-right: 0;
}

.brand span {
    font-size: 20px;
    padding-left: 7px;
}

.hamburger-bar.x:nth-of-type(1) {
    transition: all 100ms ease-in-out;
    transform: rotate(-45deg);
    transform-origin: top right;
    width: 25px;
}
  
.hamburger-bar.x:nth-of-type(2) {
    transform-origin: center;
    width: 0;
}
  
.hamburger-bar.x:nth-of-type(3) {
    transition: all 100ms ease-in-out;
    transform: rotate(45deg);
    transform-origin: bottom right;
    width: 25px;
}

.navbar-button {
    width: auto;
    font-size: 20px;
    display: inline-block;
    background-color: rgb(64 78 237);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    transition: all 200ms ease-in-out;
    margin: 0;
    position: absolute;
}

.navbar-button:hover {
    scale: 1.02;
    background-color: rgb(72, 86, 235);
}

.login {
    background-color: rgb(13, 131, 19);
    right: 115px;
}

.login:hover {
    background-color: rgb(21, 139, 27);
}

.invite {
    right: 10px;
}

.scrolled {
    background-color: rgb(23, 25, 27);
}

@media (max-width: 1075px) {
    .navbar {
        margin-right: auto;
    }

    .navbar-list {
        display: none;
        flex-direction: column;
        width: 100%;
        margin: 0;
        margin-top: 60px;
        position: relative;
    }

    .hamburger {
        display: flex;
    }

    .navbar-item {
        text-align: center;
        margin: 0px;
        padding: 4px;
    }

    .navbar-item a:after {
        content: none !important;
    }

    .navbar-list.active {
        display: flex;
        padding-bottom: 80px;
        border-bottom: 2px rgb(54, 54, 54) solid;
    }

    .brand {
        display: flex;
    }

    .invite {
        right:50%;
        margin-right:-100px;
        bottom: 20px;
    }
    
    .login {
        right:50%;
        margin-right: 0px;
        bottom: 20px;
    }
}

@media (max-width: 400px) {
    .hamburger {
        top: 10px;
        right: 10px;
    }
}