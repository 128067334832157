.command-card {
    margin: 20px 0px;
    padding: 20px 10px ;
    background-color: rgb(28, 28, 28);
    border-radius: 12px;
    text-align: left;
    position: relative;
}

.cmd-card-info, .cmd-card-extra {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 30px;
}

.cmd-card-title {
    font-size: 20px;
}

.cmd-card-desc {
    font-size: 16px;
    color: rgb(219, 219, 219);
}

.arrow {
    position: absolute;
    top: 50%;
    right: 25px;
}

.arrow::before,
.arrow::after {
  position: relative;
  content: '';
  display: block;
  width: 10px;
  height: 1px;
  background: white;
  transition: 0.3s ease-in-out;
}

.arrow::before {
  transform: rotate(45deg);
}

.arrow::after {
  left: 6px;
  top: -1px;
  transform: rotate(-45deg);
}

.command-card.dropdown .arrow::before {
  transform: rotate(-45deg);
}

.command-card.dropdown .arrow::after {
  transform: rotate(45deg);
}

.command-card:hover {
    cursor: pointer;
    background-color: rgb(32, 32, 32);
}

.cmd-card-extra {
    display: none;
    margin-top: 30px;
}

.command-card.dropdown .cmd-card-extra {
    display: block;
}

.cmd-card-sub-title {
    font-size: 17px;
}

.cmd-card-subs {
    font-size: 16px;
    color: rgb(219, 219, 219);
}