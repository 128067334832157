#intro {
    user-select: none;
}

.intro-image img {
    width: 250px;
    /* box-shadow: 1px 1px 30px 2px black; */
    border-radius: 10px;
    transition: all 400ms ease-in-out;
    animation: fade-in 600ms 100ms backwards;
    margin: 100px 0px;
}

.intro-title, .intro-desc, .intro-stats {
    transition: all 300ms ease-in-out;
    animation: fade-in 600ms 200ms backwards;
    user-select: text;
}

.intro-image img:hover {
    scale: 1.02;
}

.intro-title:hover, .intro-desc:hover, .intro-stats:hover {
    scale: 1.015;
    color: whitesmoke;
}

.intro-title {
    font-size: 42px;
    margin: auto;
    padding: 0;
    width: fit-content;
}

.intro-desc {
    font-size: 26px;
    margin: auto;
    padding: 0;
    width: fit-content;
}

.intro-stats {
    font-size: 26px;
    margin: auto;
    padding-top: 0;
    width: fit-content;
}


@media (max-width: 800px) {
    .intro-stats, .intro-desc {
        font-size: 20px;
    }

    .intro-title {
        font-size: 36px;
    }

    .intro-image img {
        width: 200px;
    }
}