#features {
    animation: fade-in 600ms 300ms backwards;
    overflow: hidden;
}

.feature-cards {
    margin: auto;
    padding: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 20px;
    grid-auto-flow: dense;
    animation: fade-in 600ms 400ms backwards;
    width: 90%;
    height: 90%;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
}

.feature-cards > .card {
    margin: 20px 20px;
    text-align: center;
    font-size: 30px;
}

.features-button {
    animation: fade-in 600ms 400ms backwards;
    font-size: 18px;
    margin-top: 30px;
}

.features-button button {
    width: 300px;
    font-size: 22px;
    display: inline-block;
    background-color: rgb(64 78 237);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin-top: 15px;
    transition: all 200ms ease-in-out;
}

.features-button button:hover {
    scale: 1.02;
    background-color: rgb(72, 86, 235);
}

@media (min-width: 1500px) {
    .feature-cards {  grid-template-columns: repeat(3, 1fr);}
}   