#footer {
    /* background-color: rgb(30, 34, 37); */
    background-color: rgb(27, 26, 26);
    width: 100%;
    padding: 50px 0px;
    margin: 0;
}

.image {
    position: relative;
    width: 100px;
    margin: auto;
}

.image img {
    width: 75px;
    border-radius: 15px;
    display: block;
    margin: 0px auto;
    border-radius: 50%;
    margin-bottom: 20px;
    transition: all 400ms ease;
}

.back-to-top {
    position: absolute;
    transform: scale(0);
    font-size: 16px;
    top: 22px;
    right: -90px;
    text-decoration: none;
    color: white;
    transition: all 400ms ease;
}

.back-to-top:hover {
    color: whitesmoke;
}

.site {
    font-size: 16px;
    color: white;
    text-decoration: none;
    width: auto;
    display: inline-block;
    margin: 5px 10px;
    padding: 5px 10px;
    transition: transform 500ms ease;
}


.site:hover {
    transform: scale(1.1);
    color: whitesmoke;
}

.image:hover .back-to-top{
    transform: scale(1);
}

.image img:hover{
    transform: scale(0.95);
}

.copyright {
    font-size: 14px;
    transition: all 500ms ease-in-out;
}

.copyright a {
    color: white;
}

.copyright a:hover {
    color: rgb(214, 214, 214);
}