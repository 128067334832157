body {
    /* background-color: rgb(35, 39, 42); */
    background-color: rgb(28, 29, 31);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 13px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(52, 56, 60);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(60, 64, 68);
    border: 1px solid rgb(60, 64, 68);
}

::-webkit-scrollbar-track-piece {
    background-color: rgb(38, 42, 46);
}

.break {
    /* width: 100%;
    margin: 0;
    margin-bottom: 150px; */
}

.line-break {
    /* width: 100%;
    margin: 0;
    margin-bottom: 150px; */
    border-bottom: 2px rgb(54, 54, 54) solid;
}

section {
    text-align: center;
    width: 75%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 18px;
}

.section-title {
    font-size: 50px;
    margin: 0;
    margin-bottom: 35px;
    transition: all 300ms ease-in-out;
}

.title-margin {
    margin-top: 25px;
}

@keyframes jump-in {
    0% {
        transform: scale(0);
    } 80% {
        transform: scale(1.1);
    } 100% {
        transform: scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(40px);   
    } 100% {
        opacity: 1;
        transform: translateY(0px);   
    }
}

@media (max-width: 800px) {
    .section-title {
        font-size: 44px;
    }
}