#premium {
    animation: fade-in 600ms 700ms backwards;
}

.premium-button {
    width: 200px;
    font-size: 22px;
    display: inline-block;
    background-color: rgb(64 78 237);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    transition: all 200ms ease-in-out;
    animation: fade-in 600ms 800ms backwards;
}

.premium-button:hover {
    scale: 1.02;
    background-color: rgb(72, 86, 235);
}